var windw = this;

$.fn.followWhen = function ( pos ) {
    var $this = this,
        $window = $(windw);

    $window.scroll(function(e){
        if ($window.scrollTop() >= pos) {
            $this.css({
                position: 'fixed',
                top: 0,
                'z-index': 9999
            });
        } else {
            $this.css({
                position: 'relative',
                top: 0
            });
        }
    });
};