$( document ).ready(function(){

  //=== Hover IMG Drop Menu ===//
  $('.web').addClass('show');

  $('.web1').hover(function(){
    $('.web').addClass('show');
    $('.app').removeClass('show');
    $('.mkt').removeClass('show');
    $('.dis').removeClass('show');
  });
  $('.app1').hover(function(){
    $('.app').addClass('show');
    $('.web').removeClass('show');
    $('.mkt').removeClass('show');
    $('.dis').removeClass('show');
  });
  $('.mkt1').hover(function(){
    $('.mkt').addClass('show');
    $('.app').removeClass('show');
    $('.web').removeClass('show');
    $('.dis').removeClass('show');
  });
  $('.dis1').hover(function(){
    $('.dis').addClass('show');
    $('.mkt').removeClass('show');
    $('.web').removeClass('show');
    $('.app').removeClass('show');
  });

  //=== New Drop Menu ===//

  $(".open-menu").click(function() {

    $mobil_content = $('.mobil-content');

    $mobil_content.slideToggle("fast");

    $("body").toggleClass("mobil-body");
  });

  // $(".open-drop").click(function() {
  //   $open_drop = $(this);
  //   $open_content = $open_drop.next();
  //
  //   $open_content.slideToggle("fast");
  // });

  $(".mobil-menu li a").click( function() { $(".open-menu").click(); });

  //=== Old Drop Menu ===//

  // $('.slick').slicknav({label:"",prependTo:$(".Header-slicknav")});
  // $(".slicknav_btn").addClass("exclude");
  // $(".slicknav_menutxt").addClass("fa-bars");
  // $(".slicknav_menutxt").addClass("fa-3x");

  // $(".slicknav_btn").click(function(){
 //  if($(this).hasClass("slicknav_open")){
 //    $(".slicknav_menu").css({
 //       "height":"100vh",
 //       "z-index":"99999999",
 //       "width":"100%",
 //       "background":"rgba(0,0,0,.97)"
 //    });
 //    $("body").css("overflow-y","hidden");
 //    $(".Header").css("background","rgba(0,0,0,0.97)");
 //  }else{
 //    $(".slicknav_menu").css({
 //       "height":"auto",
 //       "z-index":"0",
 //       "width":"auto",
 //       "background":"rgba(0,0,0,0)"
 //    });
 //    $("body").css("overflow-y","auto");
 //    $(".Header").css("background","rgba(0,0,0,0.8)");
 //  }
 // });

  // $(".slicknav_menu li a").click( function() { $(".slicknav_btn").click(); });

  $('.owl-carousel').owlCarousel({
    items: 1,
    dots: false,
    nav: true,
  });

  $('.close-msg').on('click', function(e){
    e.preventDefault();
    $('.thanks-msg').toggleClass('d-none');
  });

  $("img.hoverable").hover( function(event) {

    var original = $(this).attr("src");
    var hover = $(this).data("hover");

    $(this).attr("src", hover);
    $(this).data("hover", original);

   });

  $(".camera_wrap").camera({
    loader: "none",
    navigation: true,
    fx: "scrollHorz",
    pagination: true,
    autoAdvance: true,
    transPeriod: 700,
    pauseOnClick: false,
    playPause: false,
    navigationHover: false,
    hover: false,
    time: 2000
   });

  $(".camera_wrap_mobile").camera({
    loader: "none",
    navigation: true,
    fx: "scrollHorz",
    pagination: true,
    autoAdvance: true,
    transPeriod: 700,
    pauseOnClick: false,
    playPause: false,
    navigationHover: false,
    hover: false,
    time: 2000,
    height: '150%'
   });

  $(".proy_camera_wrap").camera({
    loader: "none",
    navigation: true,
    fx: "scrollHorz",
    pagination: false,
    thumbnails: false,
    autoAdvance: true,
    transPeriod: 700,
    pauseOnClick: false,
    playPause: false,
    navigationHover: false,
    time: 2000,
    hover: false,
   });


  $(".proy_camera_wrap_mobile").camera({
    loader: "none",
    navigation: true,
    fx: "scrollHorz",
    pagination: false,
    thumbnails: false,
    autoAdvance: true,
    hover: false,
    transPeriod: 700,
    pauseOnClick: false,
    playPause: false,
    navigationHover: false,
    time: 2000,
    height: '480px',
   });

  $('a[href^="#"]').on('click',function (e) {
    if(!$(this).hasClass("exclude")) {
      e.preventDefault();
      var target = this.hash;
      var $target = $(target);
      $('html, body').stop().animate({
          'scrollTop': $target.offset().top - 75
      }, 900, 'swing', function () {
      });
    }
  });

  $(window).scroll(function() {

    var topOfWindow = $(window).scrollTop();
    if(topOfWindow > 200) {
      $("#TopButton").fadeIn();
    } else {
      $("#TopButton").fadeOut();
    }

    $('.u-animated').each(function(){
    var imagePos = $(this).offset().top;
    var topOfWindow = $(window).scrollTop();
    var animation = 'slideUp';
    if($(this).data('animation')){
      animation = $(this).data('animation');
    }
    if (imagePos < topOfWindow+300) {
      $(this).addClass(animation);
    }
    });
  });

  var hash = window.location.hash.replace('#','').toLowerCase();
  if(hash.length > 0) {
    window.scrollTo(0, 0);

    var $target = $('#'+hash);

    $( window ).load(function() {
      if(hash == 'gracias') {
        $("#gracias").show();
        $(".thanksLatter").hide();
      }

      $('html, body').stop().animate({
          'scrollTop': $target.offset().top
      }, 900, 'swing', function () {
        window.location.hash = target;
      });
    });

  }

   $('.bxSlider').bxSlider({
      minSlides: 3,
      maxSlides: 4,
      slideWidth: 220,
      slideMargin: 10,
      pager: false,
      autoStart: true
    });

  $('.linkBlog').click(function(e){
    e.preventDefault();
    var contId = $(this).data('blog');
    $('#blog-'+contId).fadeIn();

    $('body').css('overflow-y','hidden');

    $('.closeBlogCont').click(function(e){
      e.preventDefault();

      $('#blog-'+contId).fadeOut();
      $('body').css('overflow-y','auto');

    })
  });

  $('.linkService').click(function(e){
    e.preventDefault();
    var contId = $(this).data('service');

    $('#service-'+contId).fadeIn();
    $('body').css('overflow-y','hidden');

    $('.closeServCont').click(function(e){
      e.preventDefault();

      $('#service-'+contId).fadeOut();
      $('body').css('overflow-y','auto');

    })
  });
  $(".link-ignore").click(function(e){
    e.preventDefault();
    return false;
  });
});

$(window).load(function(){
   $('.servicio').equalHeights();
   $('.news').equalHeights();
   $('.client').equalHeights();
   $('.imgInfoImg').equalHeights();
   $('p.description').equalHeights();
});
